import React from 'react';
import {Outlet} from "react-router-dom"
import Homepage from "./homepage"

function Layout2() {
	return(
      	<Homepage/>
	);
}

export default Layout2;